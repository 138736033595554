@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';
@import '../../common/typography/typography-variables';
@import '../../common/link/a-variables';

.header-nav {
  $self: &;
  display: flex;
  align-items: center;

  &__list {
    white-space: nowrap;
  }

  &__item {
    display: inline-block;
    padding-right: 28px;
  }

  &__link {
    @include inter_20_28;
    @include font-weight-regular;

    &_active {
      @include linkActive;
      @include inter_20_28;
      @include font-weight-semi-bold;
    }

    #{$self}_dark & {
      color: $white;

      &:hover {
        color: $blue5;
      }
    }
  }
}

@include mobile() {
  .header-nav {
    &__list {
      display: flex;
      flex-direction: column;
      margin: 0 0 32px 0;
    }

    &__item:not(:last-child) {
      margin: 0 0 28px 0;
      padding: 0;
    }
  }
}
