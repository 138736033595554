@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../components/common/typography/typography-variables';

.footer {
  flex: 0 0 auto;
  position: relative;
  border: 0 none;
  top: 0;

  & .content-width {
    background-color: $blue8;
  }

  &_shadow {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }

  &__wrapper-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__top-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    border-bottom: solid 2px $blue5;
    padding-bottom: 24px;
  }

  &__bottom-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-top: 24px;
    align-items: flex-end;
    gap: 0 16px;
  }

  &__bottom-messengers {
    padding: 0;
    margin: 0 0 0 12px;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    gap: 12px 0;
  }

  &__about {
    display: flex;
    align-items: center;
    gap: 20px 0;
  }

  &__about-text {
    @include inter_20_28;
    @include font-weight-semi-bold;
    vertical-align: bottom;
    text-transform: lowercase;
    white-space: nowrap;
  }

  &__shops-block {
    display: flex;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px 16px;
  }

  &__location-button {
    @include inter_15_22;
    @include font-weight-medium;
    @include location-button-default;
  }

  &__about-logo {
    width: 76px;
    height: 19px;
  }

  &__brands {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 20px;
    align-self: center;
  }
}

@include desktop() {
  .footer {
    &__icons-wrapper {
      display: flex;
      flex-direction: row;
      padding: 0;
    }

    &__messengers {
      a {
        padding: 0 12px;
      }
    }

    &__shops-block {
      padding-top: 16px;
    }

    &__menu {
      display: flex;
      flex-direction: row;
      padding-top: 0;
      padding-bottom: 0;
    }

    &__inner {
      display: flex;
      align-items: center;
      padding: 40px 60px 24px 60px;
    }

    &__buttons {
      display: none;
    }
  }
}

@include mobile() {
  .footer {
    &__top-container {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__inner {
      padding: 24px 20px;
    }

    &__info-block {
      margin-bottom: 24px;
      gap: 8px 0;
    }

    &__shops-block {
      margin-bottom: 16px;
    }

    &__about {
      flex-direction: column;
      align-items: flex-start;
    }

    &__about-divider {
      display: none;
    }

    &__about-text::first-letter {
      text-transform: capitalize;
    }

    &__about-text {
      @include inter_15_22;
      @include font-weight-medium;
    }

    &__bottom-messengers {
      display: none;
    }

    &__messengers {
      padding: 0;
    }

    &__about-logo {
      width: 80px;
      height: 20px;
    }

    &__brands {
      justify-content: flex-start;
      gap: 16px;
    }
  }
}
