@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../components/common/typography/typography-variables';
@import '../button/button-variables';

.search-panel {
  $self: &;
  position: relative;
  display: flex;
  flex-direction: column;

  &__overlay {
    content: '';
    width: 100vw;
    height: 100vh;
    background-color: $black1;
    opacity: 0.5;
    @include position(fixed, $right: 0, $top: 0);
  }

  &_focus {
    @include position(absolute, $left: 0, $top: 0, $right: 0, $z-index: 1);
  }

  &__logo {
    display: flex;
    align-items: center;
    margin: 0 54px 0 0;
  }

  &__input {
    display: block;
    width: 100%;
    height: 44px;
    padding: 11px 16px 11px 45px;
    background-color: $blue8;
    border: none;
    margin: 0;
    outline: none;
    vertical-align: middle;
    position: relative;
    text-overflow: ellipsis;
    color: $colorTextSecondary;
    border-radius: 22px;
    box-sizing: border-box;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;

    &:focus {
      background-color: $white;
      border: 1px solid $blue2;
    }

    &_active {
      @include inter_15_22;
      @include font-weight-regular;
      text-overflow: initial;
      border-radius: 6px;
      padding-left: 16px;

      &::placeholder {
        color: $colorTextSecondary !important;
      }
    }

    &::placeholder {
      color: $colorTextPrimary;
      @include inter_15_22;
      @include font-weight-medium;
    }
  }

  &__search-button {
    gap: 6px;
  }

  &__search-form {
    display: flex;
    position: relative;

    &_active {
      background-color: $white;
    }
  }

  &__search-icon {
    position: absolute;
    margin-left: 18px;
    margin-top: 12px;
    pointer-events: none;
  }

  &_focus {
    display: block;
  }

  &__suggestions {
    width: 100%;
    padding: 12px 0 8px 0;
    background: $white;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $blue8;
      @include position(absolute, $left: 0, $top: 3px, $z-index: 2);
    }
  }

  &__suggestion-item {
    padding: 8px 36px 8px 36px;
    cursor: pointer;

    &:hover {
      background-color: $blue9;
    }
  }
}

@include mobile() {
  .search-panel {
    &__search-form {
      flex-direction: column;

      &_active {
        height: 100vh;
        padding: 20px;
      }
    }

    &__logo {
      display: none;
    }

    &__search-button {
      margin-top: 20px;
      width: 100%;
      justify-content: center;
    }

    &__close-btn {
      display: none;
    }
  }
}

@include desktop() {
  .search-panel {
    flex-direction: row;

    &__suggestions {
      @include position(absolute, $left: 0, $top: calc(100% - 4px));
    }

    &__form-control {
      flex: 1;
    }

    &__suggestion-item {
      padding-left: 44px;
      padding-right: 44px;
    }

    &__search-form {
      flex: 1;
      align-items: center;

      &_active {
        height: 120px;
      }
    }

    &__logo {
      margin: 0 82px 0 60px;
    }

    &__search-icon {
      margin-top: 0;
    }

    &__buttons {
      display: flex;
      margin-right: 60px;
      gap: 20px;
    }

    &__search-button {
      padding: 11px 20px 11px 18px;
      margin-left: 20px;
    }
  }
}

@media (max-width: 1439px) {
  .search-panel {
    &__input:not(&__input_active) {
      width: 44px;
      padding: 14px;
      color: transparent;

      &::placeholder {
        color: transparent;
      }
    }

    &__search-icon {
      margin-left: 13px;
    }
  }
}

