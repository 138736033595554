@import '../../../styles/mixins';
@import '../../../styles/_variables.scss';

.footer-button-container {
  @include location-button-default;

  &_dark {
    background-color: $blue8;
  }
}
