@import "../../../styles/mixins";

.logo {
  $self: &;

  // Предзагрузка иконок
  &::before {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(../../../../assets/images/relines-logo/relines-logo-black.svg)
      url(../../../../assets/images/relines-logo/relines-logo-white-сyrillic.svg)
      url(../../../../assets/images/relines-logo/relines-logo-black-сyrillic.svg);
  }

  display: block;
  width: 158px;
  height: 36px;
  text-decoration: none;
  background: {
    image: url('../../../../assets/images/relines-logo/relines-logo-black.svg');
    repeat: no-repeat;
    size: contain;
  }

  &#{$self}_cyrillic {
    &#{$self}_dark {
      background: {
        image: url('../../../../assets/images/relines-logo/relines-logo-white-сyrillic.svg');
      }
    }

    &#{$self}_light {
      background: {
        image: url('../../../../assets/images/relines-logo/relines-logo-black-сyrillic.svg');
      }
    }
  }
}

@include mobile() {
  .logo {
    $self: &;

    &:not(&_full) {
      width: 40px;
      height: 36px;

      &#{$self}_dark {
        background: {
          image: url('../../../../assets/images/relines-logo/relines-logo-mobile-white.svg');
          repeat: no-repeat;
          size: 40px 36px;
        }
      }

      &#{$self}_light {
        background: {
          image: url('../../../../assets/images/relines-logo/relines-logo-mobile.svg');
          repeat: no-repeat;
          size: 40px 36px;
        }
      }
    }
  }
}
