@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../components/common/typography/typography-variables';

.footer-text-block {
  display: flex;
  flex-direction: column;
  gap: 8px 0;

  &__title {
    @include inter_12_16;
    @include font-weight-medium;
    @include uppercase;
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
  }

  &__body {
    @include inter_15_22;
    @include font-weight-regular;
  }
}
