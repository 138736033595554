@import '../../../styles/variables';

$button-border-width: 1px;
$button-border-radius: 6px;
$button-horizontal-padding: 11px;

@mixin button-grey {
  border: none;
  background-color: $blue8;

  &:hover,
  &:focus,
  &_focus {
    background-color: $blue7;
  }
}

@mixin button-dark {
  border: none;
  color: $blue5;
  background-color: $black1_6;

  &:hover,
  &:focus,
  &_focus {
    background-color: $black1_4;
  }
}

@mixin button-darkgrey {
  border: none;
  background-color: $black2;

  &:hover,
  &:focus,
  &_focus {
    background-color: $black1_4;
  }
}

@mixin button-rounded {
  border-radius: 22px;
  padding: 0 12px;
}

@mixin button-big {
  height: 44px;
  padding: 11px 16px;
}
