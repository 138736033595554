@import "../../../styles/mixins";

.footer-payment-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 126px;
  height: 36px;
  margin-left: auto;
}

@include mobile() {
  .footer-payment-logos {
    display: none;
  }
}
