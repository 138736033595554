@import '../../../styles/mixins';
@import '../input/input-variables';
@import '../typography/typography-variables';
@import '../../../styles/variables';

$selectOptionHoverColor: $blue9;

.select {
  $self: &;
  display: inline-block;
  min-width: 150px;
  vertical-align: middle;
  box-sizing: border-box;
  position: relative;

  #{$self}__indicator {
    color: $blue4;
    padding: 0 16px 0 0 !important;
  }

  &__value-container {
    padding: 11px 0 11px 16px !important;
  }

  &__menu {
    margin-top: 0 !important;
    border-radius: 6px !important;
    box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.1) !important;
  }

  &__placeholder {
    margin: 0 !important;
  }

  &_placeholder_black {
    #{$self}__placeholder {
      color: $colorTextPrimary;
    }
  }

  &_indicator_black {
    #{$self}__indicator {
      color: $colorTextPrimary;
    }
  }

  &__preloader {
    @include position(absolute, $right: 16px, $top: 12px);
  }

  & > &__control {
    cursor: pointer;
    @include input-base;
    border: none;
    height: 44px;
    display: flex;
    padding: 0;
    min-height: initial;

    &:hover {
      @include input-base_focus;
      border: 1px solid transparent;

      .select__indicator {
        color: $blue3;
      }
    }

    &--is-focused {
      @include input-base_focus;

      .select__indicator {
        color: $blue3;
      }
    }

    &--menu-is-open {
      .select__indicator {
        transform: rotate(180deg);
        padding: 0 0 0 16px !important;
      }
    }
  }

  &_big > &__control {
    @include input_big;
  }

  &_long {
    @include input-base_full-width;
  }

  &_filled > &__control,
  &_filled > &__control:hover {
    @include input-base_filled;
  }

  &__indicator-separator {
    display: none;
  }

  & &__single-value {
    @include inter_15_22;
    @include font-weight-regular;
    margin: 0 !important;

    #{$self}__hint {
      display: none;
    }
  }

  & &__single-value--is-disabled {
    color: $colorTextSecondary;
  }

  & &__option {
    @include inter_15_22;
    @include font-weight-regular;
    cursor: pointer;
    padding: 9px 16px;
    position: relative;

    &--is-focused {
      background-color: $selectOptionHoverColor;
    }

    &--is-selected {
      background-color: $blue9;
      @include inter_15_22;
      @include font-weight-semi-bold;

      &:before {
        content: '';
        display: block;
        width: 3px;
        height: 100%;
        background-color: $yellow2;
        border-radius: 2px;
        @include position(absolute, $top: 0, $left: 0);
      }
    }

    &:active {
      background-color: $selectOptionHoverColor;
    }

    &--is-disabled {
      cursor: initial;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &_error &__single-value {
    color: $colorTextError;
  }

  &_full-width {
    @include input-base_full-width;
  }
}
