@import "../../../../../../styles/mixins";

.verification-form {
  padding: 0;
  margin-bottom: 18px;

  &__phone-label {
    margin-bottom: 4px;
  }

  &__input {
    display: flex;
    justify-content: space-between;
  }

  &__input-text-field input {
    width: 230px;
    margin-right: 12px;
  }
}

@include mobile() {
  .verification-form {
    padding: 0 20px;

    &__input {
      justify-content: flex-start;
    }

    &__input-text-field input {
      width: 190px;
    }
  }
}

@media (max-width: 359px) {
  .verification-form {
    &__input {
      flex-direction: column;
    }

    &__input-text-field input {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
