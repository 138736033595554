@import '../../../../common/typography/typography-variables';
@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.request-token {
  padding: 0 48px 0 48px;

  &__termsPublicOfferLink {
    margin-left: 4px;
    color: $blue4;

    &:focus,
    &:hover {
      color: $blue2;
    }
  }

  &__termsPublicOfferDescription {
    padding: 0 20px;
  }
}

@include desktop() {
  .request-token {
    &__termsPublicOfferDescription {
      padding: 0;
    }
  }
}
