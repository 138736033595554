@import '../typography/typography-variables';

.currency-label {
  &__rub {
    font: inherit;
    visibility: hidden;
    font-size: 0;
  }
  &:after {
    content: attr(data-content);
  }
}
