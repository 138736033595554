@import "../../../../../styles/mixins";

.search-panel-filter-mobile {
  &__close-btn {
    @include position(absolute, $top: 12px, $right: 12px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
}

@include desktop() {
  .search-panel-filter-mobile {
    display: none;
  }
}