@import '../../../../common/typography/typography-variables';
@import '../../../../common/button/button-variables';
@import "../../../../../styles/mixins";

.verificate-token {
  &__footer {
    display: flex;
    padding: 0 20px;
  }

  &__change-phone-btn {
    padding: 0;
    color: $blue4;

    &:hover,
    &:focus {
      color: $blue2;
    }

    .button__title {
    }
  }

  &__repeat-call-btn,
  &__repeat-sms-btn {
    padding: 0;
    color: $blue4;
    margin-left: 25px;

    &:hover,
    &:focus {
      color: $blue2;
    }
  }

  &__repeat-call-btn {
    &_swapped {
      order: 1;
    }
  }

  &__repeat-sms-btn {
    &_swapped {
      order: 0;
    }
  }

  &__repeat-through {
    margin-top: 6px;
    margin-left: 24px;

    &_swapped {
      order: 2;
    }
  }
}

@include desktop() {
  .verificate-token {
    &__footer {
      padding: 0;
    }
  }
}
