@import './_variables.scss';
@import './_mixins.scss';

.content__main {
  box-sizing: border-box;
}

@include desktop() {
  .content_2col .content__inner {
    display: flex;
  }

  .content__main {
    float: left;
    width: 63.7%;
    border-right: 12px solid $blue8;
  }
}
