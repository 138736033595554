@import '../../../../../styles/variables';

.sign-in-btn {
  padding: 12px;
  &__title {
    margin-left: 8px;
    &_hidden {
      display: none;
    }
  }
}
