@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../../view/components/common/typography/typography-variables';

.brand-list {
  &__brand-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &__white-cover-image {
    @include black-cover();
    background-color: $white;
    opacity: 0.5;
  }

  &__pointer-icon {
    @include position(absolute, $left: auto, $bottom: -43px);
    z-index: 2;
    height: 0;
    transition: height .2s;

    &_visible {
      height: 12px;
    }
  }
}

@include mobile() {
  .brand-list {
    &__pointer-icon {
      display: none;
    }
  }
}
