@import '../../../../styles/mixins';

.authorization-modal {
  box-sizing: border-box;

  &__modal {
    width: 460px;
  }
}

@include mobile() {
  .authorization-modal {
    &__modal {
      width: auto;
      @include position-modal-form-on-mobile();
    }
  }
}
