@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.input-suggestions {
  width: 100%;
  padding: 4px 0;
  background: $white;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  @include position(absolute, $left: 0, $top: 100%, $z-index: 1);
  max-height: calc(100vh - 150px);
  overflow-y: scroll;

  &__item {
    padding: 4px 12px 4px 12px;
    cursor: pointer;

    &_highlighted {
      background-color: $blue9;
    }

    &_disabled {
      opacity: 0.5;
      cursor: default;
      background-color: $white;
    }
  }
}

@include mobile() {
  .input-suggestions {
    width: 100%;
  }
}

@include desktop() {
  .input-suggestions {
    max-height: 45vh;

    @include thin-scroll;
    scrollbar-color: rgba(22, 23, 23, 0.1) transparent;
  }
}
