@import './styles/_variables';
@import './styles/_mixins';
@import 'styles/content';
@import 'styles/error';
@import 'styles/fonts';
@import 'styles/focus-visible';
@import './components/common/link/a';
@import './components/common/link/link/link';
@import './components/common/tab/tab';
@import 'styles/animations';

:root {
  --fix_vh: 100%;
}

// Reset

* {
  margin: 0;
  outline: none;
  padding: 0;
}

code,
kbd,
samp,
pre,
tt,
var,
textarea,
input,
select,
isindex {
  font: inherit;
  font-size: 100%;
}

input::placeholder {
  opacity: 1;
}

dfn,
i,
cite,
var,
address,
em {
  font-style: normal;
}

th,
b,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

a,
img,
a img,
iframe,
form,
fieldset,
abbr,
acronym,
object,
applet {
  border: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
center {
  vertical-align: top;
}

th,
td {
  vertical-align: middle;
}

q {
  quotes: '' '';
}

ul,
ol,
dir,
menu {
  list-style: none;
}

sub,
sup {
  vertical-align: baseline;
}

a {
  color: inherit;
  text-decoration: none;
}

hr {
  display: none;
}

// HTML5 reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

// General

html,
body {
  width: 100%;
  font: {
    family: $fontFamilyDefault;
    size: 15px;
  }
  line-height: 25px;
  color: $black1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-variant-ligatures: normal;
  font-kerning: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: $blue8;
}

.clearfix {
  &:after {
    clear: both;
    content: ' ';
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
    width: 0;
  }

  * + html & {
    display: inline-block;
  }

  * html & {
    display: inline-block;
    height: 1%;
  }
}

figure {
  line-height: 0;

  & img {
    max-width: 100%;
    height: auto;
    width: auto \9;
  }
}

button {
  font-family: $fontFamilyDefault;
  background: none;
  border: 0 none;
  margin: 0;
  padding: 0;
}

input::-ms-clear {
  display: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@include mobile() {
  .hidden-xs {
    display: none !important;
  }
}

@include desktop() {
  .hidden-lg {
    display: none !important;
  }
}

// Main frame

.full-width {
  width: 100%;
  box-sizing: border-box;
  clear: both;
}

.content-width {
  width: 100%;
  max-width: 1720px;
  margin: 0;
  background-color: $white;
  box-sizing: border-box;
}

.page-section-new {
  border-top: 12px solid $blue8;
}

.page-section {
  margin: 8px 0;
}

@media (min-width: 917px) {
  .page-section {
    margin: 12px 12px 0 12px;

    &_flexible {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.content-wrapper {
  background-color: $white;
  padding-left: $common_padding_content_mobile;
  padding-right: $common_padding_content_mobile;

  @media (min-width: 1217px) {
    padding-left: $common_padding_content_desktop;
    padding-right: $common_padding_content_desktop;
  }
}
