@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../common/typography/typography-variables';

.sign-in {
  padding: 0;

  &__header {
    padding: 14px 12px 0 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  &__phone-label {
    margin-bottom: 4px;
  }
}

@include desktop() {
  .sign-in {
    padding: 40px 48px 40px 48px;

    &__close-btn {
      @include position(absolute, $top: 12px, $right: 12px);
    }

    &__header {
      padding: 0;
      border-bottom: none;
      margin-bottom: 24px;
    }
  }
}
