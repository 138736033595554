@import "../../common/typography/typography-variables";
@import "../../../styles/mixins";

.location-change-button {
  flex: 1;
  gap: 0 8px;
  overflow: hidden;

  &__location {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &_wide {
      max-width: 100% !important;
    }
  }

  &__delivery-info {
    color: $colorTextSecondary;
  }

  &__icon {
    flex-shrink: 0;
  }
}

@include desktop() {
  .location-change-button {
    &__location {
      max-width: 50px;
    }
  }
}

@media (min-width: 1350px) {
  .location-change-button {
    &__location {
      max-width: 120px;
    }
  }
}

@media (min-width: 1440px) {
  .location-change-button {
    &__location {
      max-width: 135px;
    }
  }
}
