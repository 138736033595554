@import './button-variables';
@import '../typography/typography-variables';

button > * {
  pointer-events: none;
}

.button {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  height: 36px;
  position: relative;
  margin: 0;
  padding: 0 $button-horizontal-padding;
  border: $button-border-width solid $black6;
  border-radius: $button-border-radius;
  background-color: $white;
  @include inter_15_22;
  @include font-weight-regular;
  text-decoration: none;
  cursor: pointer;
  vertical-align: top;
  text-align: center;
  user-select: none;
  appearance: none !important;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.1s ease-in-out;

  &_focus,
  &:focus {
    outline: none;
  }

  &_without-outline#{&}_focus,
  &_without-outline:focus {
    box-shadow: none;
  }

  &__title {
    display: inline-block;
    width: 100%;
    user-select: none;
    white-space: nowrap;
  }

  &_without-border {
    border: none;
    background-color: transparent;
  }

  &_big {
    @include button-big;
  }

  &_rounded {
    @include button-rounded;
  }

  &_loading {
    background-image: url('../../../../assets/images/load-pattern_36.svg');
    background-size: 28px 36px;
    animation: moving-bg 0.6s linear infinite;
  }

  &_loading#{&}_big {
    background-image: url('../../../../assets/images/load-pattern_44.svg');
    background-size: 28px 44px;
  }
}

.button-group {
  display: flex;
  & button {
    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-left: -$button-border-width;
    }
  }
}

input.button {
  padding-bottom: 2px;
}

@keyframes moving-bg {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 28px 0;
  }
}

.button_disabled,
.button_disabled:hover,
.button_disabled:focus,
.button:disabled,
.button:disabled:focus,
.button_focus:disabled,
.button:disabled:hover {
  color: rgba($colorTextPrimary, 0.4);
  border-color: $black6;
  cursor: default;

  .icon {
    opacity: 0.4;
  }
}

.button_red {
  background-color: $red1;
  color: $colorTextWhite;
  border: none;

  &:hover {
    background-color: $red1;

    .button__title {
      color: $colorTextWhite;
    }
  }

  .button__title {
    color: $colorTextWhite;
  }

  &.button_disabled,
  &.button_disabled:hover,
  &.button_disabled:focus,
  &:disabled,
  &:disabled:focus,
  &_focus:disabled,
  &:disabled:hover {
    $textColor: rgba($colorTextWhite, 0.4);
    background-color: $red1;
    color: $textColor;

    .button__title {
      color: $textColor;
      opacity: 1;
    }
  }
}

.button_grey {
  @include button-grey;
}

.button_lightgrey {
  border: none;
  background-color: $blue8;
}

.button_yellow {
  border-color: $yellow2;
  background-color: $yellow2;
  color: $colorTextPrimary;

  &_focus,
  &:focus {
    background: $yellow1;
  }

  &:hover {
    background: $yellow1;
  }

  .button__title {
    color: $colorTextPrimary;
  }

  &.button_disabled,
  &.button_disabled:hover,
  &.button_disabled:focus,
  &:disabled,
  &:disabled:focus,
  &_focus:disabled,
  &:disabled:hover {
    $textColor: rgba($colorTextPrimary, 0.2);
    color: $textColor;
    border-color: $yellow2;
    background: $yellow2;

    .button__title {
      color: $textColor;
      opacity: 1;
    }
  }
}

.button_dark {
  @include button-dark;
}

.button_darkgrey {
  @include button-darkgrey;
}
