@import '../../../styles/_variables.scss';
@import '../../../components/common/typography/typography-variables';
@import '../../../styles/_mixins.scss';

.cart-link {
  $self: &;

  &__btn {
    overflow: visible;
  }

  &__price {
    @include inter_15_22;
    @include font-weight-medium;
    margin-left: 8px;

    &_active {
      color: $white;
    }
  }

  &__badge {
    &_active {
      background-color: $blue2;
    }
  }

  &_dark {
    #{$self}__price {
      color: $blue5;
    }
  }
}

@media (max-width: 1619px) {
  .cart-link {
    &__price {
      display: none;
    }
  }
}

@media (min-width: 1620px) {
  .cart-link {
    &__btn {
      padding: 11px 16px;
    }
  }
}