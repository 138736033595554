@import '../input/input-variables';
@import '../../../styles/variables';

.textarea {
  @include input;
  resize: none;
  height: 110px;
  width: 173px;
  padding-top: 5px;

  &_height_small {
    height: 60px;
  }

  &_height_big {
    height: 132px;
  }
}
