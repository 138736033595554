@import "../../../../../../styles/mixins";

.request-token-form {
  &__phone-label {
    margin-bottom: 4px;
  }

  &__input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__input-text-field {
    input {
      width: 234px;
      margin-right: 12px;
    }

    .form-control__helper-text {
      white-space: pre-wrap;
    }
  }
}

@include mobile() {
  .request-token-form {
    padding: 0 20px;

    &__input {
      justify-content: flex-start;
    }

    &__input-text-field input {
      width: 190px;
    }
  }
}

@media (max-width: 359px) {
  .request-token-form {
    &__input {
      flex-direction: column;
    }

    &__input-text-field {
      input {
        width: 100%;
        margin-bottom: 8px;
      }

      .form-control__helper-text {
        margin: 12px 0 12px 0;
      }
    }
  }
}
