@use 'sass:math';
@import '../../../styles/_variables.scss';
@import '../../../styles/mixins.scss';

.icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-image: url('../../../../assets/images/icons/icons-sprite.svg');
  background-repeat: no-repeat;
}

$gridSize: 20px;
$defaultSize: 20px;
$small: 16px;
$big: 36px;

@function position($col, $row, $size: $defaultSize) {
  $offset: math.div($gridSize - $size, 2);
  @if $offset < 0 {
    $offset: $offset * -1;
  }
  @return (-$gridSize * $col - $offset) (-$gridSize * $row - $offset);
}

@mixin with-mobile() {
  @content;
  @include mobile() {
    :not(#nonexistent-id) > &-mobile {
      // add priority to mobile rules
      @content;
    }
  }
}

.icon_big {
  transform: scale(1.2);
}

.icon_cart {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(0, 0)};
    --gray: #{position(0, 1)};
    --red: #{position(0, 2)};
    --white: #{position(0, 3)};
    --blue5: #{position(0, 4)};
    --blue1: #{position(0, 5)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_blue5 {
      --variant: var(--blue5);
    }

    &_blue1 {
      --variant: var(--blue1);
    }
  }
}

.icon_search {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(2, 0)};
    --gray: #{position(2, 1)};
    --red: #{position(2, 2)};
    --white: #{position(2, 3)};
    --blue5: #{position(2, 4)};
    --blue1: #{position(2, 5)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_blue5 {
      --variant: var(--blue5);
    }

    &_blue1 {
      --variant: var(--blue1);
    }
  }
}

.icon_search-small {
  background-position: var(--variant);

  @include with-mobile {
    width: $small;
    height: $small;

    --default: #{position(3, 0, $small)};
    --gray: #{position(3, 1, $small)};
    --red: #{position(3, 2, $small)};
    --white: #{position(3, 3, $small)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_darkAzure {
      background-image: url('../../../../assets/images/icons/search-dark-azure.svg');
    }
  }
}

.icon_phone {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(4, 0)};
    --gray: #{position(4, 1)};
    --red: #{position(4, 2)};
    --white: #{position(4, 3)};
    --blue2: #{position(4, 6)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_blue2 {
      --variant: var(--blue2);
    }
  }
}

.icon_phone-small {
  background-position: var(--variant);

  @include with-mobile {
    width: $small;
    height: $small;

    --default: #{position(5, 0, $small)};
    --gray: #{position(5, 1, $small)};
    --red: #{position(5, 2, $small)};
    --white: #{position(5, 3, $small)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }
  }
}

.icon_cross {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(6, 0)};
    --gray: #{position(6, 1)};
    --red: #{position(6, 2)};
    --white: #{position(6, 3)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }
  }
}

.icon_cross-small {
  background-position: var(--variant);

  @include with-mobile {
    width: $small;
    height: $small;

    --default: #{position(7, 0, $small)};
    --gray: #{position(7, 1, $small)};
    --red: #{position(7, 2, $small)};
    --white: #{position(7, 3, $small)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }
  }
}

.icon_location {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(8, 0)};
    --gray: #{position(8, 1)};
    --red: #{position(8, 2)};
    --white: #{position(8, 3)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }
  }
}

.icon_location-arrow {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(30, 17)};
    --blue5: #{position(30, 16)};

    &_default {
      --variant: var(--default);
    }

    &_blue5 {
      --variant: var(--blue5);
    }
  }
}

.icon_location-small {
  background-position: var(--variant);

  @include with-mobile {
    width: $small;
    height: $small;

    --default: #{position(9, 0, $small)};
    --gray: #{position(9, 1, $small)};
    --red: #{position(9, 2, $small)};
    --white: #{position(9, 3, $small)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }
  }
}

.icon_plus {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(10, 0)};
    --gray: #{position(10, 1)};
    --red: #{position(10, 2)};
    --white: #{position(10, 3)};
    --black2: #{position(10, 4)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_black2 {
      --variant: var(--black2);
    }
  }
}

.icon_minus {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(11, 0)};
    --gray: #{position(11, 1)};
    --red: #{position(11, 2)};
    --white: #{position(11, 3)};
    --black2: #{position(11, 4)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_black2 {
      --variant: var(--black2);
    }
  }
}

.icon_lock {
  background-position: var(--variant);

  @include with-mobile {
    width: $small;
    height: $small;

    --default: #{position(12, 0, $small)};
    --gray: #{position(12, 1, $small)};
    --red: #{position(12, 2, $small)};
    --white: #{position(12, 3, $small)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }
  }
}

.icon_chevron-down {
  background-position: var(--variant);
  width: $defaultSize;
  height: $defaultSize;

  @include with-mobile {
    --default: #{position(13, 0, $small)};
    --gray: #{position(13, 1, $small)};
    --red: #{position(13, 2, $small)};
    --white: #{position(13, 3, $small)};
    --denim: #{position(13, 4)};
    --bluish: #{position(13, 5)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_denim {
      --variant: var(--denim);

      &:hover {
        --variant: var(--bluish);
      }
    }
  }
}

.icon_check-circle {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(14, 0)};

    &_default {
      --variant: var(--default);
    }
  }
}

.icon_clock-bg {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(15, 0)};
    --gray: #{position(15, 1)};

    &_default {
      --variant: var(--default);
    }
    &_gray {
      --variant: var(--gray);
    }
  }
}

.icon_cross-circle {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(16, 0)};

    &_default {
      --variant: var(--default);
    }
  }
}

.icon_chevron-left {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(17, 0)};
    --gray: #{position(17, 1)};
    --white: #{position(17, 3)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_white {
      --variant: var(--white);
    }
  }
}

.icon_chevron-right {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(18, 0)};
    --gray: #{position(18, 1)};
    --light-gray: #{position(17, 5)};
    --white: #{position(18, 3)};
    --light: #{position(18, 5)};
    --small: #{position(18, 6)};

    &_default {
      --variant: var(--default);
    }
    &_gray {
      --variant: var(--gray);
    }
    &_black6 {
      --variant: var(--light-gray);
    }
    &_white {
      --variant: var(--white);
    }
    &_light {
      --variant: var(--light);
    }
    &_small {
      --variant: var(--small);
    }
  }
}

.icon_menu {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(19, 0)};
    --gray: #{position(19, 1)};
    --red: #{position(19, 2)};
    --white: #{position(19, 3)};
    --blue5: #{position(19, 4)};
    --blue1: #{position(19, 5)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_blue5 {
      --variant: var(--blue5);
    }

    &_blue1 {
      --variant: var(--blue1);
    }
  }
}

.icon_radio-checked {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(20, 0)};

    &_default {
      --variant: var(--default);
    }
  }
}

.icon_radio {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(21, 0)};

    &_default {
      --variant: var(--default);
    }
  }
}

.icon_checkbox-checked {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(22, 0)};

    &_default {
      --variant: var(--default);
    }
  }
}

.icon_checkbox {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(23, 0)};

    &_default {
      --variant: var(--default);
    }
  }
}

.icon_grid {
  background-position: var(--variant);

  @include with-mobile {
    width: $small;
    height: $small;

    --default: #{position(28, 0, $small)};
    --light: #{position(28, 1, $small)};

    &_default {
      --variant: var(--default);
    }

    &_light {
      --variant: var(--light);
    }
  }
}

.icon_list {
  background-position: var(--variant);

  @include with-mobile {
    width: $small;
    height: $small;

    --default: #{position(29, 0, $small)};
    --light: #{position(29, 1, $small)};

    &_default {
      --variant: var(--default);
    }

    &_light {
      --variant: var(--light);
    }
  }
}

.icon_folder {
  background-position: var(--variant);

  @include with-mobile {
    width: 80px;
    height: 60px;

    --default: #{position(24, 0)};
    --red: #{position(24, 3)};

    &_default {
      --variant: var(--default);
    }

    &_red {
      --variant: var(--red);
    }
  }
}

.icon_telegram {
  background-position: var(--variant);
  @include with-mobile {
    width: 24px;
    height: 24px;

    --default: #{position(34, 2)};
    --hover: #{position(34, 4)};
    --small: #{position(34, 0)};
    --hover-small: #{position(34, 1)};

    &_default {
      --variant: var(--default);

      &:hover,
      &:focus {
        --variant: var(--hover);
      }
    }

    &_small {
      --variant: var(--small);
      width: 20px;
      height: 20px;

      &:hover,
      &:focus {
        --variant: var(--hover-small);
      }
    }
  }
}

.icon_whatsApp {
  background-position: var(--variant);
  @include with-mobile {
    width: 24px;
    height: 24px;

    --default: #{position(36, 2)};
    --hover: #{position(36, 4)};
    --small: #{position(36, 0)};
    --hover-small: #{position(36, 1)};

    &_default {
      --variant: var(--default);

      &:hover,
      &:focus {
        --variant: var(--hover);
      }
    }

    &_small {
      --variant: var(--small);
      width: 20px;
      height: 20px;

      &:hover,
      &:focus {
        --variant: var(--hover-small);
      }
    }
  }
}

.icon_advantages {
  background-position: var(--variant);
  @include with-mobile {
    width: 16px;
    height: 16px;

    --default: #{position(40, 0, 16px)};

    &_default {
      --variant: var(--default);
    }
  }
}

.icon_disAdvantages {
  background-position: var(--variant);
  @include with-mobile {
    width: 16px;
    height: 16px;

    --default: #{position(41, 0, 16px)};

    &_default {
      --variant: var(--default);
    }
  }
}

.icon_close {
  background-position: var(--variant);
  width: $defaultSize;
  height: $defaultSize;
  @include with-mobile {
    --default: #{position(42, 0)};
    --red: #{position(42, 0)};

    &_default {
      --variant: var(--default);
    }

    &_red {
      --variant: var(--red);
    }
  }
}

.icon_back {
  background-position: var(--variant);
  width: $defaultSize;
  height: $defaultSize;
  @include with-mobile {
    --default: #{position(17, 4)};

    &_default {
      --variant: var(--default);
    }
  }
}

.icon_forward {
  background-position: var(--variant);
  width: $defaultSize;
  height: $defaultSize;
  @include with-mobile {
    --default: #{position(18, 4)};
    --blue: #{position(18, 9)};

    &_default {
      --variant: var(--default);
    }
    &_light {
      --variant: var(--blue);
    }
  }
}

.icon_placemark {
  background-position: var(--variant);
  width: 30px;
  height: 30px;
  @include with-mobile {
    --default: #{position(30, 4)};
    --white: #{position(29, 10)};

    &_default {
      --variant: var(--default);
    }
    &_white {
      --variant: var(--white);
      width: 64px;
      height: 76px;
    }
  }
}

.icon_user {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(43, 0)};
    --gray: #{position(43, 1)};
    --red: #{position(43, 2)};
    --white: #{position(43, 3)};
    --blue5: #{position(43, 4)};
    --blue1: #{position(43, 5)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_blue5 {
      --variant: var(--blue5);
    }

    &_blue1 {
      --variant: var(--blue1);
    }
  }
}

.icon_logOut {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(44, 0)};
    --gray: #{position(44, 1)};
    --red: #{position(44, 2)};
    --white: #{position(44, 3)};
    --blue5: #{position(44, 4)};
    --blue1: #{position(44, 5)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_blue5 {
      --variant: var(--blue5);
    }

    &_blue1 {
      --variant: var(--blue1);
    }
  }
}

.icon_triangle {
  background-image: url('../../../../assets/images/icons/triangle.svg');
  width: 10px;
  height: 12px;
}

.icon_big-arrow-left {
  @include with-mobile {
    background-position: #{position(17, 7)};

    width: $defaultSize;
    height: $big;
  }
}

.icon_big-arrow-right {
  @include with-mobile {
    background-position: #{position(18, 7)};

    width: $defaultSize;
    height: $big;
  }
}

.icon_pointer {
  @include with-mobile {
    background-position: #{position(24, 6)};

    width: 24px;
    height: 12px;
  }
}

.icon_pickup {
  @include with-mobile {
    background-position: #{position(45, 0)};
    width: 60px;
    height: 72px;
  }
}

.icon_courier {
  @include with-mobile {
    background-position: #{position(45, 4)};
    width: 60px;
    height: 72px;
  }
}

.icon_russianPost {
  @include with-mobile {
    background-position: #{position(45, 8)};
    width: 60px;
    height: 72px;
  }
}

.icon_checklist {
  @include with-mobile {
    background-position: #{position(45, 12)};
    width: 60px;
    height: 72px;
  }
}

.icon_phone-big {
  @include with-mobile {
    background-position: #{position(45, 16)};
    width: 60px;
    height: 72px;
  }
}

.icon_pin {
  @include with-mobile {
    background-position: #{position(30, 13)};
    width: 27px;
    height: 38px;
  }
}

.icon_yandex {
  background-position: var(--variant);
  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(48, 0)};
    --small: #{position(48, 3)};

    &_default {
      --variant: var(--default);
    }

    &_small {
      --variant: var(--small);
      width: $small;
      height: $small;
    }
  }
}

.icon_google {
  background-position: var(--variant);
  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(48, 1)};
    --small: #{position(48, 4)};

    &_default {
      --variant: var(--default);
    }

    &_small {
      --variant: var(--small);
      width: $small;
      height: $small;
    }
  }
}

.icon_gis {
  background-position: var(--variant);
  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(48, 2)};
    --small: #{position(48, 5)};

    &_default {
      --variant: var(--default);
    }

    &_small {
      --variant: var(--small);
      width: $small;
      height: $small;
    }
  }
}

.icon_star {
  background-position: var(--variant);
  width: $defaultSize;
  height: $defaultSize;
  @include with-mobile {
    --default: #{position(49, 0)};
    --white: #{position(49, 1)};
    --light: #{position(49, 2)};

    &_default {
      --variant: var(--default);
    }

    // На самом деле она жёлтая
    &_white {
      --variant: var(--white);
    }

    &_light {
      --variant: var(--light);
    }
  }
}

.icon_delivery {
  @include with-mobile {
    background-position: #{position(48, 8)};
    width: 60px;
    height: 72px;
  }
}

.icon_payment {
  @include with-mobile {
    background-position: #{position(48, 12)};
    width: 60px;
    height: 72px;
  }
}

.icon_warranty {
  @include with-mobile {
    background-position: #{position(48, 16)};
    width: 60px;
    height: 72px;
  }
}

.icon_clock {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(0, 7)};
    --gray: #{position(0, 8)};
    --red: #{position(0, 9)};
    --white: #{position(0, 10)};
    --blue5: #{position(0, 11)};
    --blue1: #{position(0, 12)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_blue5 {
      --variant: var(--blue5);
    }

    &_blue1 {
      --variant: var(--blue1);
    }
  }
}

.icon_trash {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(1, 7)};
    --gray: #{position(1, 8)};
    --red: #{position(1, 9)};
    --white: #{position(1, 10)};
    --blue5: #{position(1, 11)};
    --blue1: #{position(1, 12)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_blue5 {
      --variant: var(--blue5);
    }

    &_blue1 {
      --variant: var(--blue1);
    }
  }
}

.icon_warning {
  background-position: var(--variant);

  @include with-mobile {
    width: $defaultSize;
    height: $defaultSize;

    --default: #{position(2, 7)};
    --gray: #{position(2, 8)};
    --red: #{position(2, 9)};
    --white: #{position(2, 10)};
    --blue5: #{position(2, 11)};
    --blue1: #{position(2, 12)};

    &_default {
      --variant: var(--default);
    }

    &_gray {
      --variant: var(--gray);
    }

    &_red {
      --variant: var(--red);
    }

    &_white {
      --variant: var(--white);
    }

    &_blue5 {
      --variant: var(--blue5);
    }

    &_blue1 {
      --variant: var(--blue1);
    }
  }
}
