@import '../../../../styles/mixins';

//Миксин с общими стилями для логотипов, которые используются для футера на всех экранах и на главной странице на мобиле
@mixin brandItemCommonStyle {
  height: 20px;
  object-fit: cover;

  &_img_chery {
    width: 48px;
    object-position: 0;
  }

  &_img_geely {
    width: 51px;
    object-position: -49px;
  }

  &_img_omoda {
    width: 97px;
    object-position: -100px;
  }

  &_img_haval {
    width: 98px;
    object-position: -198px;
  }

  &_img_byd {
    width: 57px;
    object-position: -339px;
  }

  &_img_shacman {
    width: 21px;
    object-position: -297px;
  }

  &_img_foton {
    width: 20px;
    object-position: -318px;
  }

  &_img_great-wall {
    width: 31px;
    object-position: -397px;
  }

  &_img_lifan {
    width: 28px;
    object-position: -428px;
  }

  &_img_changan {
    width: 24px;
    object-position: -477px;
  }

  &_img_brilliance {
    width: 23px;
    object-position: -501px;
  }

  &_img_faw {
    width: 32px;
    object-position: -525px;
  }

  &_img_dongfeng {
    width: 20px;
    object-position: -557px;
  }

  &_img_howo {
    width: 20px;
    object-position: -578px;
  }

}

.brand-item {
  &__footer {
    @include brandItemCommonStyle;
  }
}

.brand-item {
  &__main {
    height: 24px;
    object-fit: cover;

    &_img_chery {
      width: 58px;
      object-position: 0;
    }

    &_img_geely {
      width: 61px;
      object-position: -58px;
    }

    &_img_omoda {
      width: 116px;
      object-position: -120px;
    }

    &_img_haval {
      width: 117.6px;
      object-position: -238px;
    }

    &_img_byd {
      width: 69px;
      object-position: -406px;
    }

    &_img_shacman {
      width: 25px;
      object-position: -356px;
    }

    &_img_foton {
      width: 25px;
      object-position: -381px;
    }

    &_img_great-wall {
      width: 37px;
      object-position: -476px;
    }

    &_img_lifan {
      width: 34px;
      object-position: -513px;
    }

    &_img_changan {
      width: 28px;
      object-position: -573px;
    }

    &_img_brilliance {
      width: 28px;
      object-position: -601px;
    }

    &_img_faw {
      width: 38px;
      object-position: -630px;
    }

    &_img_dongfeng {
      width: 25px;
      object-position: -668px;
    }

    &_img_howo {
      width: 25px;
      object-position: -693px;
    }
  }
}

@include mobile() {
  .brand-item {
    &__main {
      @include brandItemCommonStyle;
    }
  }
}


@media (min-width: 1720px) {
  .brand-item {
    &__main {
      height: 28px;
      object-fit: cover;

      &_img_chery {
        width: 67px;
        object-position: 0;
      }

      &_img_geely {
        width: 71px;
        object-position: -68px;
      }

      &_img_omoda {
        width: 135px;
        object-position: -140px;
      }

      &_img_haval {
        width: 137px;
        object-position: -276px;
      }

      &_img_byd {
        width: 80px;
        object-position: -474px;
      }

      &_img_shacman {
        width: 29px;
        object-position: -415px;
      }

      &_img_foton {
        width: 28px;
        object-position: -445px;
      }

      &_img_great-wall {
        width: 43px;
        object-position: -555px;
      }

      &_img_lifan {
        width: 39px;
        object-position: -599px;
      }

      &_img_changan {
        width: 33px;
        object-position: -668px;
      }

      &_img_brilliance {
        width: 32px;
        object-position: -701px;
      }

      &_img_faw {
        width: 44px;
        object-position: -734px;
      }

      &_img_dongfeng {
        width: 29px;
        object-position: -779px;
      }

      &_img_howo {
        width: 28px;
        object-position: -809px;
      }
    }
  }
}