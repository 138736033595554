@import '../../../styles/variables';
@import '../../../components/common/typography/typography-variables';
@import '../../../components/common/link/a-variables';
@import '../../../styles/mixins';

.footer {
  &__menu {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 0;
  }

  @include interpunct('#{&}__menu', '#{&}__menu-item');

  &__menu-link {
    white-space: nowrap;
    color: $blue2;

    &:hover,
    &:focus {
      color: $blue1;
    }

    &_active {
      @include linkHover;
    }
  }
}

@include desktop() {
  .footer {
    &__nav {
      margin: 0;
    }

    &__menu {
      display: flex;
    }
  }
}
