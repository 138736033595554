@import '../../../styles/_mixins.scss';

.filters-form-mobile {
  width: 100%;

  &__close-btn {
    @include position(absolute, $top: 12px, $right: 12px);
  }

  &__title {
    margin-bottom: 20px;
  }

  &__car-option {
    margin-bottom: 20px;
  }

  &__apply-btn {
    width: 100%;
  }
}
