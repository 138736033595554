@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';
@import './typography-variables';

.typography {
  &__root {
    margin: 0;
  }
  &_align_left {
    text-align: left;
  }
  &_align_center {
    text-align: center;
  }
  &_align_right {
    text-align: right;
  }
  &_align_justify {
    text-align: justify;
  }
  &_no-wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &_gutter-bottom {
    margin-bottom: 0.35em;
  }
  &_display_inline {
    display: inline;
  }
  &_display_block {
    display: block;
  }
  &_color_inherit {
    color: inherit;
  }

  &_inter_60_72 {
    @include inter_60_72;
  }
  &_inter_48_60 {
    @include inter_48_60;
  }
  &_inter_40_52 {
    @include inter_40_52;
  }
  &_inter_30_40 {
    @include inter_30_40;
  }
  &_inter_24_32 {
    @include inter_24_32;
  }
  &_inter_20_28 {
    @include inter_20_28;
  }
  &_inter_15_22 {
    @include inter_15_22;
  }
  &_inter_13_18 {
    @include inter_13_18;
  }
  &_inter_12_16 {
    @include inter_12_16;
  }

  &_regular {
    @include font-weight-regular;
  }
  &_medium {
    @include font-weight-medium;
  }
  &_semiBold {
    @include font-weight-semi-bold;
  }

  &_uppercase {
    @include uppercase;
  }

  &_color_primary {
    color: $colorTextPrimary;
  }
  &_color_secondary {
    color: $colorTextSecondary;
  }
  &_color_error {
    color: $red1;
  }
  &_color_white {
    color: $colorTextWhite;
  }

  &_bold {
    font-weight: bold;
  }
}
