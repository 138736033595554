@import '../../../styles/mixins';
@import '../../../styles/variables';

.location-modal {
  padding: 28px 48px;
  max-width: 520px;
  box-sizing: border-box;
  overflow-y: visible;

  &__close-button {
    @include position(absolute, $top: 16px, $right: 16px);
  }

  &__heading {
    margin-bottom: 24px;
    margin-right: 36px;
  }

  &__settings {
    margin-bottom: 28px;
  }

  &__shipper-icons {
    margin-right: -16px;
  }
}

@include mobile() {
  .location-modal {
    @include position-modal-form-on-mobile();
    padding: 14px 20px;

    &__close-button {
      @include position(absolute, $top: 20px, $right: 20px);
    }

    &__heading {
      margin-right: 45px;
      margin-bottom: 20px;

      &:after {
        display: block;
        content: '';
        height: 2px;
        margin-left: -10000em;
        margin-right: -10000em;
        margin-top: 14px;
        background: $red1;
      }
    }

    &__settings {
      margin-bottom: 20px;
    }
  }
}
