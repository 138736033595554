.delivery-companies {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 12px;
  opacity: 0.6;

  &__icon {
    display: inline-block;
    vertical-align: top;
  }
}
