@import '../../../styles/_mixins.scss';
@import '../../../styles/variables';
@import '../../../styles/z-index-constants';
@import '../../../components/common/typography/typography-variables';

.header {
  $self: &;
  z-index: $zHeader;
  box-sizing: border-box;
  position: relative;
  margin: 0 0 1px 0;

  &_overlay {
    z-index: $zHeaderOverlay;
  }

  &__inner {
    display: flex;
    height: 68px;
    position: relative;

    &_shadow {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  &__progress-bar {
    display: none;
  }

  &__center {
    display: flex;
    margin: 0 96px 0 0;
  }

  &__logo {
    display: flex;
    align-items: center;
    margin: 0 54px 0 0;
  }

  &__location {
    display: flex;
    margin: 0 0 0 20px;
  }

  &__location-button {
    height: auto;
    padding: 11px 20px;
  }

  &__right {
    white-space: nowrap;
    margin: 0 0 0 20px;

    button {
      margin: 0 0 0 20px;
    }
  }

  &__cart {
    display: inline-block;
  }

  &__phone_container {
    display: inline-block;
    align-self: center;
  }

  &__phone {
    @include inter_20_28;
    @include font-weight-regular;
    width: 177px;
    white-space: nowrap;
  }

  &__mobile-nav-button {
    cursor: pointer;
  }

  &_dark {
    margin: 0;

    #{$self}__inner {
      background-color: $black2;
    }

    #{$self}__phone {
      color: $white;

      &:hover {
        color: $blue5;
      }
    }
  }
}

@media (max-width: 359px) {
  .header {
    &__logo {
      margin: 0 76px 0 0;
    }

    &__inner {
      justify-content: space-between;
    }

    &__content {
      flex: 0;
    }
  }
}

@include desktop() {
  .header {
    margin: 0;

    &__inner {
      height: 120px;
    }

    &__progress-bar {
      display: block;
      @include position(absolute, $left: 0px, $bottom: -2px);
      width: 0;
      height: 2px;
      background-color: $red1;
      margin: 0 0 0 $common_padding_content_desktop;

      &_started {
        width: 91%;
        transition: width 30s cubic-bezier(0, 1.16, 0.09, 0.94) 0s;
      }

      &_complete {
        transition: width 300ms linear 0s;
        width: calc(100% - #{$common_padding_content_desktop * 2});
      }

      &_hidden {
        display: none;
      }
    }

    &__logo {
      margin: 0 82px 0 0;
    }

    &__search-form {
      margin: 30px 0;
    }
  }
}

@media (max-width: 1390px) {
  .header {
    &__logo {
      margin: 0 80px 0 0;
    }

    &__center {
      margin: 0 10px 0 0;
    }
  }
}

@media (min-width: 1440px) {
  .header {
    &__search {
      flex-grow: 1;
    }
  }
}

@include mobile() {
  .header {

    .content-wrapper {
      padding-right: 12px;
    }

    &__location {
      display: none;
    }

    &__logo {
      margin: 0 50px 0 0;
    }
  }
}
