@import "../../../../../styles/mixins";

.search-panel-filter-desktop {
  margin-right: 20px;
  width: 232px;

  &_long {
    width: calc(100% - 20px);
    max-width: 480px;
    min-width: 232px;
  }
}

@include mobile() {
  .search-panel-filter-desktop {
    display: none;
  }
}