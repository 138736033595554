@import '../../../styles/mixins';

.preloader {
  width: 20px;
  height: 20px;
  background-color: $blue8;
  background-image: url('../../../../assets/images/icons/loader.svg');
  background-repeat: no-repeat;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
