@import '../../styles/_variables.scss';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: var(--fix_vh, 100%);
  min-height: 100vh;
  width: 100%;
  max-width: 1720px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background-color: $blue8;

  &_fix_vh {
    --fix_vh: calc(var(--vh, 100vh));
    height: 100vh;
    height: var(--fix_vh);
  }

  &__main {
    position: relative;
    flex: 1 0 auto;

    display: flex;
    flex-direction: column;

    > *:first-child {
      border-top: none;
    }
  }
}
