@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';
@import './input-variables';

.input {
  @include input;

  &_loading {
    background-image: url('../../../../assets/images/load-pattern_36.svg');
    background-size: 28px 36px;
    animation: moving-bg 0.6s linear infinite;
  }
}
