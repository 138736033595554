@import '../../../typography/typography-variables';
@import '../../../button/button-variables';
@import '../../../../../styles/_mixins';
@import '../../../../../styles/_variables.scss';
@import '../../../input/input-variables';

$menuWidth: 232px;

.menu-item-divider {
  background-color: $blue7;
}

.menu-item-header {
  @include inter_15_22;
  @include font-weight-regular;
  text-transform: none;
}

.menu-item-placeholder {
  padding: 9px 16px;

  &__text {
    @include inter_15_22;
    @include font-weight-regular;
    color: $black5;
  }
}

.menu-item-link {
  &__item-content {
    width: 100%;
    height: 100%;
    padding: 9px 16px;
  }
}

.menu-item-wrapper {
  &__item-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.1);
    padding: 0.534rem 0;
    width: $menuWidth;
    border-radius: $inputBorderRadius;
    @include inter_15_22;
    @include font-weight-regular;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(22, 23, 23, 0.1);
      border-radius: 4px;
    }

    //Стили для стилизации скроллбара в Firefox
    scrollbar-width: thin;
    scrollbar-color: rgba(22, 23, 23, 0.1) transparent;
  }

  &__submenu-item {
    & > div {
      padding: 9px 16px;
      background-color: initial;

      &::after {
        display: none;
      }
    }
  }

  &__icon-right {
    position: absolute;
    right: 16px;
  }
}

.menu-item-link,
.menu-item-wrapper {
  $self: &;

  &__item {
    padding: 0;
    background-color: initial;

    &::after {
      color: $blue6;
    }

    &:hover {
      background-color: $blue9;
    }

    &:hover a {
      color: initial;
    }
  }

  &__item_active {
    @include inter_15_22;
    @include font-weight-semi-bold;

    #{$self}__category-link,
    #{$self}__item-content {
      @include inter_15_22;
      @include font-weight-semi-bold;
    }
  }

  // Жёлтая полоска в начале - признак активного
  // (текущего выбранного пункта меню)
  &__item_active,
  // Для подменю добавляем жёлтую полоску, когда оно открыто
  // (дочерний элемент li имеет модификатор focused)
  &__item > &__submenu-item-content_focused,
  // Для элементов меню жёлтая полоска добавляется в момент клика
  &__item:active {
    background-color: $blue9;
    &::before {
      content: '';
      display: block;
      width: 3px;
      height: 100%;
      background-color: $yellow2;
      border-radius: 2px;
      transition: background-color 0.6s;
      @include position(absolute, $top: 0, $left: 0);
    }
  }
}
