@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.location-form {
  white-space: normal;

  &__controls-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
  }

  &__location-name-input {
    flex-grow: 1;
    margin-right: 12px;
    box-sizing: border-box;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  &__error-message {
    margin-top: 16px;
  }
}
