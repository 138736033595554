@import '../../../styles/_variables';
@import '../../../styles/breakpoints';
@import '../../common/typography/typography-variables';

%element-base {
  margin-top: 0.66rem;
}

.common-error {
  &__title {
    @include inter_60_72;
  }
  &__solution-hint {
    @extend %element-base;
  }
  &__action {
    @extend %element-base;
  }
}
