@import '../../../styles/_variables.scss';
@import '../../../components/common/typography/typography-variables';
@import '../../../styles/_mixins.scss';

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 8px;
  height: 14px;
  padding: 4px 7px 2px 7px;
  background-color: $red1;
  border: 2px solid $red1;
  border-radius: 12px;
  @include position(absolute, $top: -8px, $right: -10px);
  @include inter_12_16;
  @include font-weight-semi-bold;
  @include uppercase;
  @include add-letter-spacing;
  color: $colorTextWhite;
  letter-spacing: initial;

  &_border {
    border: 2px solid $white;
  }
}
