@import '../../../styles/_variables.scss';

.footer-messengers {
  flex: 0;

  &__messenger:not(:last-child) {
    border-right: 1px solid $blue6;
  }

  &__messenger {
    padding: 0 12px;
  }

  &_dark {
    background-color: $blue8;
  }
}
