@import '../../typography/typography-variables';
@import '../a-variables';
@import '../../../../styles/_variables.scss';

.link {
  @include inter_15_22;
  @include font-weight-regular;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    @include linkHover;
  }

  &:focus {
    color: $blue1;
  }

  &_active {
    @include linkActive;
  }

  &_blue {
    color: $blue2;

    &:hover,
    &:focus {
      color: $blue1;
    }
  }

  &_lightBlue {
    color: $blue4;

    &:hover,
    &:focus {
      color: $blue3;
    }
  }

  &_black {
    color: $black1;

    &:hover,
    &:focus {
      color: $blue2;
    }
  }

  &_grey {
    color: $black4;

    &:hover,
    &:focus {
      color: $blue3;
    }
  }
}
