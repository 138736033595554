@import '../../../styles/variables';
@import '../../../components/common/typography/typography-variables';
@import '../../../components/common/link/a-variables';
@import '../../../styles/mixins';
@import '../../../styles/breakpoints';

.footer-shop-info {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px 40px;

  @include interpunct('#{&}__shops', '#{&}__shop');

  &__shops {
    display: flex;
    flex-wrap: wrap;
  }

  &__more-shops {
    white-space: nowrap;
  }

  &__phone,
  &__email {
    &:hover {
      color: $blue2;
    }
  }
}

@include mobile() {
  .footer-shop-info {
    gap: 24px;

    &__shops {
      flex-direction: column;
    }

    @include interpunct('#{&}__shops', '#{&}__shop') {
      flex: unset;
      margin-bottom: 4px;
    }
  }
}

@include desktop() {
  .footer-shop-info {
    width: 606px;
  }
}
