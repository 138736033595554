@import '../typography/typography-variables';
@import '../button/button-variables';
@import '../../../styles/_mixins';
@import '../../../styles/_variables.scss';
@import '../input/input-variables';

$menuWidth: 232px;

.menu {
  $self: &;

  &__menu-button {
    width: $menuWidth;
    height: 44px;
    padding: 10px 20px;
    border-radius: $inputBorderRadius;
    text-align: left;
    @include inter_15_22;
    @include font-weight-regular;
    box-sizing: border-box;
    background-color: $blue8;
    position: relative;
    z-index: 1;

    &_disabled {
      pointer-events: none;
    }

    &_open {
      .menu__icon-down {
        transform: scaleY(-1);
      }
    }

    &_full-width {
      @include input-base_full-width;
    }

    &:hover {
      .menu__icon-down {
        --variant: var(--bluish);
      }
    }
  }

  &__preloader {
    @include position(absolute, $right: 0, $bottom: -10px);
  }

  &__icon-down {
    float: right;
  }

  &__menu {
    box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.1);
    padding: 0.534rem 0;
    width: $menuWidth;
    border-radius: $inputBorderRadius;
    @include inter_15_22;
    @include font-weight-regular;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(22, 23, 23, 0.1);
      border-radius: 4px;
    }

    //Стили для стилизации скроллбара в Firefox
    scrollbar-width: thin;
    scrollbar-color: rgba(22, 23, 23, 0.1) transparent;
  }

  &__menu-content {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__title {
    flex: 90;
    margin-right: 5px;
    @include truncate();

    &_grey {
      color: $black5;
    }
  }
}
