@import '../../../styles/_mixins.scss';
@import '../../../styles/_variables.scss';
@import '../../../components/common/typography/typography-variables';

$inputMarginLeft: 12px;
$inputBackgroundColor: $blue8;
$inputBorderRadius: 6px;

@mixin input {
  @include input-base;

  &::placeholder {
    @include input-base__placeholder;
  }

  &_full-width {
    @include input-base_full-width;
  }

  &:hover {
    @include input-base_hover;
  }

  &_filled {
    @include input-base_filled;
  }

  &_filled:hover {
    @include input-base_hover;
  }

  &_height_big {
    height: 44px;
    width: 100%;
  }

  &:focus,
  &_filled:focus {
    background: $white;
    border: 1px solid $blue2;
  }
}

input[type='text']:disabled {
  opacity: 0.5;
}

@mixin input-base {
  display: inline-block;
  font-size: 1rem;
  line-height: normal;
  height: 36px;
  padding: 2px $inputMarginLeft;
  background-color: $inputBackgroundColor;
  border: 1px solid transparent;
  border-radius: $inputBorderRadius;
  margin: 0;
  outline: none;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  position: relative;
}

@mixin input_big {
  height: 44px;
  width: 228px;
}

@mixin input-base_full-width {
  width: 100%;
}

@mixin input-base__placeholder {
  color: $colorTextSecondary;
}

@mixin input-base_hover {
  background: $blue7;
}

@mixin input-base_filled {
  color: $colorTextPrimary;
}

@mixin input-base_focus {
  box-shadow: none;
  background: $blue7;
}