@import '../../styles/mixins';
@import '../../styles/variables';

.modal {
  display: inline-block;
  top: 0;
  cursor: default;
  outline: 0;

  &_default {
    max-width: 100%;
    min-width: 278px;
    text-align: left;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
  }

  &_verticallyCenter {
    vertical-align: middle;
    @include translate($x: -50%, $y: -50%);
    @include position(absolute, $left: 50%, $top: 50%);
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    overflow: hidden auto;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;

    &_overlayClickDisabled {
      cursor: initial;
    }
  }
}

@media (min-width: 601px) {
  .modal {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }
}

@media (min-width: 601px) and (max-height: 500px) {
  .modal {
    max-height: calc(100vh - 10px);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .modal {
    &__overlay {
      animation-duration: 300ms;
      animation-name: slidein;
    }
  }
}

@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
