@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.header-global-notification {
  min-height: 180px;
  padding: 30px 50px 30px 290px;
  background-color: $yellow2;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    width: 28px;
    height: 10px;
    background: url('../../../../assets/images/notification-bottom@2x.png') no-repeat 0 0;
    background-size: 28px 10px;
    @include position(absolute, $left: 109px, $bottom: -9px, $z-index: 1);
  }

  a {
    text-decoration: underline;
  }

  &__title {
    white-space: pre-wrap;
    margin: 0;
  }

  &__text {
    white-space: pre-wrap;
    margin: 10px 0 5px 0;
  }

  &__close-button {
    width: 26px;
    height: 26px;
    padding: 0;
    @include position(absolute, $top: 20px, $right: 20px);
  }
}

@include mobile() {
  .header-global-notification {
    padding-left: 20px;
  }
}
