@import '../../../styles/_variables.scss';
@import '../typography/typography-variables';
@import './a-variables';

.a {
  @include inter_15_22;
  @include font-weight-regular;
  text-decoration: none;
  transition: color 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    @include linkHover;
  }

  &:focus {
    color: $blue1;
  }

  &_active {
    @include linkActive;
  }
}
