@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../common/typography/typography-variables';

$textMargin: 6px;

.form-control {
  &__label-container {
    margin-bottom: $textMargin;
    @include truncate;
  }

  &__helper-text {
    margin-top: 12px;
  }
}
