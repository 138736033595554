@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../components/common/typography/typography-variables';

.popup-sidebar {
  width: 100%;
  min-width: 280px;
  max-width: 360px;
  height: 100%;
  min-height: 100%;
  background: $white;
  overflow-y: auto;
  text-align: left;
  transition: all 0.2s ease-out;
  @include position(absolute, $top: 0, $left: 0);

  &__header {
    height: 68px;
    border-bottom: 1px solid $blue8;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    margin: 17px 0 0 20px;
  }

  &__close {
    cursor: pointer;
    margin: 12px;
  }

  &__menu {
    padding: 20px;
    border-bottom: 1px solid $blue8;
  }

  &__contacts {
    display: flex;
    justify-content: space-between;
    max-width: 360px;
  }

  &__phone_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 179px;
    height: 44px;
    background: $blue8;
    border-radius: 6px;
  }

  &__phone {
    @include inter_15_22;
    @include font-weight-medium;
  }

  &__messengers {
    width: 121px;
  }

  &__footer {
    padding: 20px 20px 0 20px;
  }
  &__title-lk {
    margin-bottom: 12px;
  }
  &__authorization-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 359px) {
  .popup-sidebar {
    &__phone_container {
      margin: 0 0 20px 0;
    }

    &__contacts {
      flex-direction: column;
    }
  }
}
